.appbar {
  height: 22px;
  background-color: #353535;
  border-bottom: 1px solid rgba(173, 161, 161, 0.5);
  background-clip: padding-box;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.modebar svg {
    margin: 0 5px;
   cursor: pointer;
}
.modebar svg:first-of-type{
    margin-left: 0;
}
.modebar svg:last-of-type{
    margin-right: 0;
}
