
.result-pane {
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  padding: 20px;
  /* Left scrollbar */
  direction: rtl;

  color: #444;
  font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
  font-size: 16px;
  line-height: 1.5em;
}
.react-markdown {
    direction:ltr;
  }


table {
  margin-top: 1rem;
  border: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

/*tr:nth-child(even) {background-color: #f2f2f2;}*/
