body {
  margin: 0;
  color: inherit;
  font-size: 1em;
}

*,
*::before,
*::after {
   -webkit-box-sizing: inherit; 
   box-sizing: inherit; 
}

.toolbar {
  display: flex; 
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 0 20px;
}

.toolbar > ul {
  display: flex;
  margin: 0;
  padding: 0 5px;
}


.toolbar > ul > li {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  margin: 8px 4px;
  border-radius: 4px;
  line-height: normal;
}


.toolbar > ul > li:hover {
  cursor: pointer;
  background: #e9e9e9;
}

.toolbar > ul > li > a {
  display: flex;
  color: inherit;
}

.toolbar > ul > li > a:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

.toolbar > ul > li > a:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}
